// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/common/loader/global-loader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/common/loader/global-loader.tsx");
  import.meta.hot.lastModified = "1705927853745.5205";
}
// REMIX HMR END

import React from "react";
import { useNavigation } from "@remix-run/react";
export const GlobalLoader = () => {
  _s();
  const navigation = useNavigation();
  const active = navigation.state !== "idle";
  return <>
      {active && <div className="absolute inset-0 z-[999] backdrop-blur-[4px] ">
          <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
            <img className="glanum-logo-anime h-[60px] w-[60px]" src="/images/Icon-rosace-red.svg" alt="" />
          </div>
        </div>}
    </>;
};
_s(GlobalLoader, "I2WaJhUM5KV32aS1+j3KKeVsgyA=", false, function () {
  return [useNavigation];
});
_c = GlobalLoader;
var _c;
$RefreshReg$(_c, "GlobalLoader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;